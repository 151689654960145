<template>
  <div class="page-layout">
    <div class="reset-container" ref="resetContainer">
      <div class="div_desc">
        <i class="icon-joyo i_iconfont2">&#xe746;</i>
        <span class="desc">为了您的账号安全，首次登陆请修改密码</span>
      </div>
      <div class="div_title">修改密码</div>
      <form class="form-wrapper">
        <div
          class="input-field border-bottom-1px"
          :class="{ active: mobileFocus === 1 }"
        >
          <i class="iconfont icon-joyo">&#xe721;</i>
          <cube-input
            class="cube-input-wrapper"
            @focus="focusInput(1)"
            @blur="blurInput"
            v-model="pwdParams.value"
            :clearable="pwdParams.clearable"
            :placeholder="pwdParams.placeholder"
            :type="pwdParams.type"
            :maxlength="pwdParams.maxlength"
            :autocomplete="true"
            :eye="pwdParams.eye"
          ></cube-input>
        </div>
        <div
          class="input-field border-bottom-1px"
          :class="{ active: mobileFocus === 2 }"
        >
          <i class="iconfont icon-joyo">&#xe721;</i>
          <cube-input
            class="cube-input-wrapper"
            @focus="focusInput(2)"
            @blur="blurInput"
            v-model="passParams.value"
            :clearable="passParams.clearable"
            :placeholder="passParams.placeholder"
            :type="passParams.type"
            :maxlength="passParams.maxlength"
            :autocomplete="true"
            :eye="passParams.eye"
          ></cube-input>
        </div>
      </form>
      <div class="login-btn">
        <cube-button
          :primary="true"
          @click="verifyLogin"
          :disabled="loginBtnDisable"
          class="button"
          >{{ loginBtnText }}
        </cube-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import md5 from 'blueimp-md5';
import { resetPwdFirst } from '../../api/sign-in';
import { passReg } from '../../lib/validate';

export default {
  name: 'first-reset',
  data() {
    return {
      pwdParams: {
        value: '',
        placeholder: '新密码，6-16位数字及字母组合',
        type: 'password',
        eye: {
          open: false,
          reverse: false
        },
        clearable: {
          visible: true,
          blurHidden: false
        }
      },
      passParams: {
        value: '',
        placeholder: '确认密码',
        type: 'password',
        eye: {
          open: false,
          reverse: false
        },
        clearable: {
          visible: true,
          blurHidden: false
        }
      },
      mobileFocus: 0,
      showPassword: true,
      loginBtnDisable: false,
      adminUid: localStorage.getItem('USERNAME') ? JSON.parse(atob(localStorage.getItem('USERNAME'))) : null
    };
  },
  computed: {
    loginBtnText() {
      return this.loginBtnDisable ? '保存中' : '保存';
    },
    ...mapGetters(['orgAndPostList'])
  },

  methods: {
    focusInput(n) {
      this.mobileFocus = n;
    },

    blurInput() {
      this.mobileFocus = 0;
      setTimeout(() => {
        if (this.mobileFocus === 0) {
          window.scrollTo(0, 0);
        }
      }, 0);
    },
    verifyLogin() {
      const pwd = this.pwdParams.value;
      const password = this.adminUid.pwd;
      if (pwd.trim() === '') {
        return this.$showToast('请输入新密码');
      } else if (!passReg(pwd)) {
        return this.$showToast('请输入正确的新密码');
      } else if (md5(pwd) === password) {
        return this.$showToast('新密码与原密码不能相同');
      }
      const pass = this.passParams.value;
      if (pass.trim() === '') {
        return this.$showToast('请输入确认密码');
      } else if (pwd !== pass) {
        return this.$showToast('确认密码和新密码不一致');
      }
      this.loginBtnDisable = true;
      this._resetPwd();
    },
    _resetPwd() {
      window.scrollTo(0, 0);
      const pass = md5(this.passParams.value);
      resetPwdFirst(this.adminUid.account, this.adminUid.pwd, pass, this.adminUid.tenancyId, this.adminUid.deptId, this.adminUid.postId, this.adminUid.loginSource)
        .then(res => {
          this.loginBtnDisable = false;
          if (res.flag) {
            this.$showToast('登录成功');
            localStorage.setItem('USERNAME', btoa(JSON.stringify({
              loginSource: this.adminUid.loginSource
            })));
            this.setUserInfo(res.data);
            this.setToken(res.data.token);
            this.setOrgAndPostList(res.data.postRoleResList);
            this.$router.push({ name: 'home' });
          } else {
            this.$showToast(res.errorMsg);
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch(err => {
          this.loginBtnDisable = false;
        });
    },
    ...mapMutations({
      setUserInfo: 'SET_USER_INFO',
      setToken: 'SET_TOKEN',
      setOrgAndPostList: 'SET_ORG_AND_POST_LIST'
    })
  },

  mounted() {
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
      // 兼容 safari 的 100vh
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    } else if (/(Android)/i.test(navigator.userAgent)) {
      // 兼容安卓小键盘
      const height = localStorage.getItem('screenHeight') || window.innerHeight;
      this.$refs.resetContainer.style.height = `${height}px`;
      localStorage.setItem('screenHeight', height);
    }
  }
};
</script>

<style lang="scss" scoped>
.page-layout {
  height: 100%;
}

.reset-container {
  height: 100%;
  background: #ffffff;
  .div_desc{
    padding-top: 10px;
    padding-bottom: 10px;
    display: inline-block;
    width: 100%;
    background-color:#FFFBE6;
    .i_iconfont2{
      float: left;
      color: #F7A000;
      height: 15px;
      line-height: 15px;
      font-size: 15px;
      margin-left: 10px;
      padding-right: 10px;
      text-align: left;
    }
    .desc{
      float: left;
      color: #F7A000;
      line-height: 1;
      font-size: 14px;
      text-align: left;
      width: calc(100% - 45px);
    }
  }
  .div_title{
    padding-top: 63px;
    font-size: 30px;
    color: #333;
    padding-left: 26px;
    padding-bottom: 40px;
    text-align: left;
  }
}

.form-wrapper {
  padding: 0 25px;

  .input-field {
    display: flex;
    align-items: center;
    padding: 11px 0 2px;
    animation: all 0.3s;

    .icon-joyo {
      color: #aaaaaa;
    }

    &.active {
      .icon-joyo {
        color: $color-joyo;
      }
    }

    input {
      flex: 1;
      margin: 0 14px;
    }

    .icon-input {
      width: 17px;
      height: 17px;
    }

    .input-append {
      &.close {
        width: 15px;
        height: 15px;
      }
    }
  }

  .msg-field {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    .msg-code {
      width: 70px;
      height: 40px;
    }
  }
}

.login-btn {
  margin: 60px 25px 0;

  .button {
    background: $color-joyo;
  }
}

.icon-eye {
  width: 19px;
  height: 14px;
  margin-left: 10px;
}
</style>
